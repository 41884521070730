import React from 'react'
import dan from '../../../assets/images/story/skutopia/dan.png'
import image1 from '../../../assets/images/story/skutopia/skutopia-1.png'
import image2 from '../../../assets/images/story/skutopia/skutopia-2.png'
import image3 from '../../../assets/images/story/skutopia/skutopia-3.png'
import image4 from '../../../assets/images/story/skutopia/skutopia-4.png'
import image5 from '../../../assets/images/story/skutopia/skutopia-5.png'
import { InlineQuote } from '../../blocks/InlineQuote'
import Content from '../Content'
import { ProductScreenshot } from '../ProductScreenshot'

const SkutopiaBody = () => {
  return (
    <Content
      sidebar={{
        company: (
          <p>
            SKUTOPIA is a fast-growing Australian startup building an order
            fulfillment and Robotics-as-a-Service solution. The platform allows
            ecommerce businesses to manage orders, inventory, shipping, and
            warehousing — all in one place.
          </p>
        ),
        href: 'https://www.skutopia.com/',
        location: <p>Founded in Sydney, Australia, scaling internationally</p>,
        headcount: <p>80+ employees, 30+ engineers</p>,
        customers: <p>2023</p>,
      }}
      main={
        <>
          <p>
            Dan Gaskin is an engineering manager at SKUTOPIA, where he leads a
            remote-first squad that consists of three engineering teams. One of
            the main pain points Dan was looking to solve was the limited
            visibility into the bottlenecks that were slowing down the
            distributed engineering teams.
          </p>
          <p>
            Around the same time, SKUTOPIA was planning to rapidly grow its
            engineering headcount and enter international markets with their
            ecommerce order fulfillment solution.
          </p>
          <InlineQuote
            quote="We’re growing fast and anticipating ramping up the team in the near future. We’re also looking into breaking out into international markets outside of Australia, which will make the team even more distributed."
            name="Dan Gaskin"
            title="Engineering Manager"
            photo={dan}
          />
          <p>
            With fast growth and geographically distributed teams, it was
            important for the engineering managers at SKUTOPIA that the solution
            they’d choose would help them scale the company’s open feedback
            culture and help them quickly take action on the issues and ideas
            software engineers brought up.
          </p>
          <InlineQuote
            quote="We used to get developer feedback through a team health update meeting. It was a safe environment where people felt comfortable bringing up issues. The problem was that collecting this brutally honest — and genuinely helpful — feedback through meetings got harder as we grew."
            name="Dan Gaskin"
            title="Engineering Manager"
            photo={dan}
          />
          <p>
            Overall, the growing organization was in need of a tool that would
            allow them to measure the right things, complement those healthy
            measures with sentiment data from engineers, and allow them to
            create a great place for engineers to work.
          </p>
          <InlineQuote
            quote="For us, the most important thing is to focus on improving what makes an engineer’s life harder or causes frustration. These are the pain points that we want to solve."
            name="Dan Gaskin"
            title="Engineering Manager"
            photo={dan}
          />

          <h2>
            Combining survey data with engineering metrics and developer tooling
          </h2>

          <p>
            When Dan and his team started looking into solutions that would help
            them improve developer experience and productivity, it was important
            that the product they’d choose would allow them to combine developer
            feedback with engineering metrics, such as DORA.
          </p>
          <ProductScreenshot src={image1} />
          <InlineQuote
            quote="Having the metrics in Swarmia means that we can try different improvements, iterate, and see whether the change was good or bad. Surveys give us the same information, but from an emotional perspective."
            name="Dan Gaskin"
            title="Engineering Manager"
            photo={dan}
          />
          <p>
            In addition to the system metrics and developer feedback through
            surveys, the engineering teams at SKUTOPIA have been particularly
            excited about the feedback loops they’re able to automate with the
            help of Swarmia’s Working Agreements and Slack notifications.
          </p>
          <p>
            For example, one of the teams Dan leads used to be in the habit of
            having a dozen pull requests open at any given time. Together, the
            team agreed to limit the number to 6 PRs, allowing them to focus on
            completing work, rather than having lots of work happening in
            parallel.
          </p>
          <InlineQuote
            quote="Since adopting the working agreement to limit the number of open pull requests, the team hasn’t gone over the limit once. They’re now able to focus on finishing the work they’ve started, rather than trying to multitask."
            name="Dan Gaskin"
            title="Engineering Manager"
            photo={dan}
          />
          <ProductScreenshot src={image2} />

          <p>
            Perhaps the most loved Swarmia feature among the SKUTOPIA engineers
            has been the Slack integration, which not only sends developers
            timely notifications about code reviews, failed CI checks, and
            GitHub comments but also allows them to react and reply to GitHub
            comments without leaving Slack.
          </p>
          <InlineQuote
            quote="We can have full conversations with replies directly with the Swarmia Slack bot rather than logging into GitHub. We get a notification if anything in our commit fails a check or if anything wonky happens in a pipeline during deploy. The days of babysitting a merge are gone."
            name="Dan Gaskin"
            title="Engineering Manager"
            photo={dan}
          />
          <ProductScreenshot src={image3} />

          <h2>Improved developer experience and streamlined productivity</h2>
          <p>
            Dan and the whole SKUTOPIA engineering organization have been happy
            with the end-to-end visibility Swarmia provides. In a short time,
            they’ve noticed a positive impact on their code review times, which
            has allowed them to dramatically reduce their cycle times.
          </p>
          <InlineQuote
            quote="I don’t think there is an engineer in the world that doesn’t want to get their code out faster. Without knowing where we had bottlenecks in the ticket lifecycle, we were unable to introduce effective measures."
            name="Dan Gaskin"
            title="Engineering Manager"
            photo={dan}
          />
          <ProductScreenshot src={image4} />
          <p>
            Developer surveys, on the other hand, have allowed the managers to
            scale the culture of openness and honest feedback — but with the
            added psychological safety of anonymous answers. This way, the
            engineers can more freely express their frustrations, concerns, and
            improvement ideas.
          </p>
          <InlineQuote
            quote="The benefit of surveys is that we’re able to tap into the emotional side of engineering. We get insight into what causes an engineer frustration, happiness, or sadness. This helps us decide which improvements we should focus our energy on next."
            name="Dan Gaskin"
            title="Engineering Manager"
            photo={dan}
          />
          <ProductScreenshot src={image5} />
          <p>
            Overall, Swarmia has given the SKUTOPIA engineering organization
            exactly what they were looking for: a continuous improvement
            platform that scales as the organization grows and matures.
          </p>
          <InlineQuote
            quote="We’re using Swarmia to improve anything and everything we can within our software development lifecycle. The end-to-end visibility means that we’re not limited in what we can improve."
            name="Dan Gaskin"
            title="Engineering Manager"
            photo={dan}
          />
        </>
      }
    />
  )
}
export default SkutopiaBody
